import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    max-width: 600px;
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    max-width: 100%;
    padding: 32px 0;
  }
`

export const DescriptionWrapper = styled.div`
  max-width: 588px;
  color: ${(props) => props.color ?? '#333'};

  @media (max-width: ${size.lg}) {
    max-width: 100%;
  }

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const Wrapper = styled.div`
  background-color: #fff;
`
