import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  Content,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FindYourSpecialist.styles'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'

export const FindYourSpecialist = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <Title.H2>
                Подберите специалиста и запишитесь на сессию уже сегодня
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Переживание развода — тема, которую нельзя отложить «на потом».
                Столкнувшись с вероятностью развода, сэкономьте время на подбор
                подходящего специалиста —{' '}
                <a
                  href="https://youtalk.ru/wizard/"
                  rel="noreferrer"
                  target="_blank"
                >
                  оставьте заявку
                </a>
                , и в течение суток вы сможете обсудить дату первой консультации
                со своим психологом.
              </Text.Large>
              <Text.Large semiBold>
                Подберите парного специалиста, чтобы спасти отношения или
                психолога для себя и ребенка, чтобы пройти этот период с
                ощущением опоры, а не разрушающегося мира.
              </Text.Large>
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Найдите личного эксперта под ваш запрос прямо сейчас" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
