import React from 'react'
import {
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TitleH3asH2style,
  Wrapper
} from './ConsultationRazvod.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const ConsultationRazvod = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper>
              <TitleH3asH2style>
                Консультирование семейных пар при угрозе развода
              </TitleH3asH2style>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Психологическая помощь при разводе начинается даже до того
                момента, как брак официально распадается. Часто пары приходят к
                психологу в надежде спасти союз, наладить отношения и разрешить
                конфликты. В этом контексте психолог помогает парам обсуждать
                проблемы, находить компромиссы и искать пути к решению
                конфликтов, если это возможно.
              </Text.Large>
              <Text.Large semiBold>
                Если наладить отношения невозможно или не хочется, то семейный
                терапевт поможет выстроить коммуникацию о разводе, восстановить
                нейтральные отношения и научиться быть партнерами по воспитанию
                детей.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Консультирование семейных пар при угрозе развода" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
