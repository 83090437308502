import cardFiveIcon from '../../../../static/img/Razvod/psychoHelp-image-5.svg'
import cardFourIcon from '../../../../static/img/Razvod/psychoHelp-image-4.svg'
import cardOneIcon from '../../../../static/img/Razvod/psychoHelp-image.svg'
import cardThreeIcon from '../../../../static/img/Razvod/psychoHelp-image-3.svg'
import cardTwoIcon from '../../../../static/img/Razvod/psychoHelp-image-2.svg'
import styled from 'styled-components'
import { Grid, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const CustomGrid = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;
`

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;

  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 32px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: ${size.sm}) {
    margin-bottom: 16px;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: ${size.md}) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  height: 491px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 32px;
  padding: 24px;

  @media (max-width: ${size.lg}) {
    height: 569px;
  }

  @media (max-width: ${size.md}) {
    height: auto;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }
`

export const TextWrapper = styled.div``

export const CardOneImage = styled.img.attrs(() => ({
  src: cardOneIcon
}))``

export const CardTwoImage = styled.img.attrs(() => ({
  src: cardTwoIcon
}))``

export const CardThreeImage = styled.img.attrs(() => ({
  src: cardThreeIcon
}))``

export const CardFourImage = styled.img.attrs(() => ({
  src: cardFourIcon
}))``

export const CardFiveImage = styled.img.attrs(() => ({
  src: cardFiveIcon
}))``
