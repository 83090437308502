import heartIcon from '../../../../static/img/Razvod/heart-break.svg'
import styled from 'styled-components'
import { Button, Text } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #2963a3;
`
export const Content = styled.div`
  padding: 132px 0 60px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.lg}) {
    padding: 123px 0 72px;
  }

  @media (max-width: ${size.md}) {
    padding: 99px 82px 48px;
  }

  @media (max-width: ${size.sm}) {
    padding: 83px 32px 32px;
  }

  @media (max-width: ${size.xs}) {
    padding: 73px 16px 32px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;
    width: 100%;

    ${Button.NewPrimary} {
      width: 100%;
    }
  }
`

export const HeartImage = styled.img.attrs(() => ({
  src: heartIcon
}))`
  text-align: center;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
`

export const TitleCard = styled.div`
  color: #333;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: center;

  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const CardContainer = styled.div`
  width: 792px;
  padding: 40px 24px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;
  margin: 0 auto;
  position: relative;

  @media (max-width: ${size.md}) {
    width: 604px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const TextWrapper = styled(Text.Large)`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #333;
  text-align: center;

  @media (max-width: ${size.sm}) {
    font-size: 16px;
    line-height: 24px;
  }
`
