import React from 'react'
import {
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardThreeImage,
  CardTwoImage
} from './CardsBlock.styles'

export const cardData = [
  {
    title: 'Эмоциональная боль и стресс',
    text: 'Развод сопровождается чувствами потери, горя, вины, а также неуверенностью в будущем. Эти эмоции могут ошеломлять и выбивать из колеи.',
    image: <CardOneImage />
  },
  {
    title: 'Сомнения и вина',
    text: 'Многие люди винят себя за развод, думая, что они сделали что-то не так или могли предотвратить разрыв отношений. Нередко они сталкиваются с осуждением от родственников или общества, подкрепляющим вину.',
    image: <CardTwoImage />
  },
  {
    title: 'Изменения в роли и идентичности',
    text: 'После развода многие люди чувствуют потерю своей роли в семье и теряют понимание собственной идентичности. Это особенно актуально для тех, кто разводится после долгого брака, ведь годами и десятилетиями их жизнь была неотрывно связана с ним',
    image: <CardThreeImage />
  },
  {
    title: 'Потеря социальной сети',
    text: 'Развод может привести к потере общих друзей и социальной поддержки, могут разрушаться близкие отношения с родственниками партнера.',
    image: <CardFourImage />
  },
  {
    title: 'Перестройка жизни',
    text: 'Часто развод связан с большими изменениями в привычном укладе: переезд, разделение имущества, потеря ритуалов, изменение рутины дня и т.д. ',
    image: <CardFiveImage />
  }
]
