import React from 'react'
import {
  Card,
  CardsContainer,
  Content,
  CustomGrid,
  ImageWrapper,
  TextWrapper,
  TitleCard,
  TitleWrapper,
  Wrapper
} from './PshychoOnlineHelp.styles'
import { Text, Title } from '../../../../youtalk-storybook/src/ui'
import { cardData } from './cardData'

export const PshychoOnlineHelp = () => (
  <Wrapper>
    <section>
      <CustomGrid>
        <Content>
          <TitleWrapper color="#fff">
            <Title.H2>
              Психологическая онлайн помощь во время и после развода
            </Title.H2>
          </TitleWrapper>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <TitleCard>{card.title}</TitleCard>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </CustomGrid>
    </section>
  </Wrapper>
)
