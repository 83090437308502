import React from 'react'
import {
  Br,
  ButtonsWrapper,
  Content,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './HeroBlock.styles'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'

export const HeroBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H1>
                Онлайн консультация
                <br /> психолога
                <Br /> при разводе
              </Title.H1>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Психологическая онлайн-помощь во время и после развода
              </Text.Large>
              <SeoPriceForTitleBlock />
            </DescriptionWrapper>
            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
          <ImageWrapper>
            <HeroImage alt="Онлайн консультация психолога при разводе" />
          </ImageWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
