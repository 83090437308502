import React from 'react'
import {
  BlockWrapper,
  ButtonsWrapper,
  Content,
  ImageFour,
  ImageOne,
  ImageThree,
  ImageTwo,
  ImageWrapper,
  InfoContainer,
  ListItem,
  ListTitle,
  ListWrapper,
  TitleSection,
  TitleWrapper,
  Wrapper
} from './StepsToFind.styles'
import { Button, Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { Event as GAEvent } from '../../../components/GA'

export const StepsToFind = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <TitleSection>
              Как найти и подобрать психотерапевта
              <br />с помощью нашего сервиса
            </TitleSection>
          </TitleWrapper>
          <BlockWrapper>
            <InfoContainer>
              <TitleWrapper>
                <ListTitle>
                  1. Заполните анкету. 10 вопросов, которые займут 6 минут
                </ListTitle>
              </TitleWrapper>

              <TitleWrapper>
                <Text.Large bold>Укажите ключевые моменты:</Text.Large>
              </TitleWrapper>

              <ListWrapper>
                <ListItem>
                  <Text.Large semiBold>
                    Как вы себя чувствуете последнее время?
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    С чем нужна помощь — например, с карьерой, отношениями или
                    переживанием потери?
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Важен ли вам пол и возраст психолога, а также подход, в
                    котором работает специалист?
                  </Text.Large>
                </ListItem>
              </ListWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Заполнить анкету
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>

            <ImageWrapper>
              <ImageOne />
            </ImageWrapper>
          </BlockWrapper>

          <BlockWrapper>
            <InfoContainer>
              <TitleWrapper>
                <ListTitle>
                  2. Алгоритм подберет подходящих специалистов
                </ListTitle>
              </TitleWrapper>
              <Text.Large semiBold>
                Изучите анкеты и выберите психолога, с которым хотите начать
                терапию.
              </Text.Large>
            </InfoContainer>

            <ImageWrapper>
              <ImageTwo />
            </ImageWrapper>
          </BlockWrapper>

          <BlockWrapper>
            <InfoContainer>
              <TitleWrapper>
                <ListTitle>3. Выберите дату и время первой сессии</ListTitle>
              </TitleWrapper>

              <ListWrapper>
                <ListItem>
                  <Text.Large semiBold>
                    Зарегистрируйтесь на платформе YouTalk при помощи номера
                    телефона.
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Затем оплатите первую сессию — это гарантирует, что
                    выбранное время у специалиста будет зарезервировано для вас.
                    Принимаются российские и зарубежные карты.
                  </Text.Large>
                </ListItem>
                <ListItem>
                  <Text.Large semiBold>
                    Сразу после оплаты мы отправим вам SMS или сообщение в
                    WhatsApp с памяткой для подготовки к первой сессии.
                  </Text.Large>
                </ListItem>
              </ListWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  href="/wizard/"
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                >
                  Начать терпапию
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>

            <ImageWrapper>
              <ImageThree />
            </ImageWrapper>
          </BlockWrapper>

          <BlockWrapper>
            <InfoContainer>
              <TitleWrapper>
                <ListTitle>
                  4. Управляйте расписанием в личном кабинете
                </ListTitle>
              </TitleWrapper>
              <Text.Large semiBold>
                Выбирайте дату и время сессии, оплачивайте и переносите встречи.
              </Text.Large>
            </InfoContainer>
            <ImageWrapper>
              <ImageFour />
            </ImageWrapper>
          </BlockWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
