import React from 'react'
import {
  CardFiveImage,
  CardFourImage,
  CardOneImage,
  CardThreeImage,
  CardTwoImage
} from './PshychoOnlineHelp.styles'

export const cardData = [
  {
    title: 'Эмоциональная поддержка',
    text: 'Психолог может предоставить безусловную эмоциональную поддержку, слушая вас и помогая выразить ваши чувства.',
    image: <CardOneImage />
  },
  {
    title: 'Управление стрессом',
    text: 'Специалист поможет вам найти эффективные стратегии для управления стрессом, например, медитации, дыхательные техники и физическую активность.',
    image: <CardTwoImage />
  },
  {
    title: 'Работа с чувствами вины и страха',
    text: 'Психолог поможет вам разобраться в ваших чувствах вины и страха, подскажет способы заботиться о себе, использовать самосострадание и принимать взвешенные решения.',
    image: <CardThreeImage />
  },
  {
    title: 'Самоопределение и личностное развитие',
    text: 'Психолог может помочь вам пересмотреть свои ценности, цели и жизненные планы, чтобы адаптироваться к новой реальности вашей жизни.',
    image: <CardFourImage />
  },
  {
    title: 'Развитие навыков коммуникации',
    text: 'Специалист поможет научиться эффективно общаться с бывшим супругом, детьми и другими людьми, выстраивать отношения из новой роли.',
    image: <CardFiveImage />
  }
]
