import React from 'react'
import { Button } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  Content,
  HeartImage,
  TextWrapper,
  TitleCard,
  Wrapper
} from './MentalStress.styles'
import { Event as GAEvent } from '../../../components/GA'

export const MentalStress = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <HeartImage />
          <TitleCard>
            Все это — серьезные стрессоры пошатывающие ментальное здоровье
          </TitleCard>
          <TextWrapper>
            Важно понимать, что психолог работает как нейтральный посредник,
            который помогает вам общаться и слушать друг друга, а также
            разрабатывать стратегии улучшения коммуникации и решения конфликтов
            и для сохранения отношений, и для комфортного их разрыва.
          </TextWrapper>
        </CardContainer>
        <ButtonsWrapper>
          <Button.NewPrimary
            href="/wizard/"
            onClick={GAEvent.openSemeinyiPageFirstScreen}
            size="large"
            type="link"
          >
            Подобрать психолога
          </Button.NewPrimary>
        </ButtonsWrapper>
      </Content>
    </section>
  </Wrapper>
)
