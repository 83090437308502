import React from 'react'
import {
  CardContainer,
  Content,
  HeartImage,
  TextWrapper,
  TitleCard,
  Wrapper
} from './AdaptationAndRestore.styles'

export const AdaptationAndRestore = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <HeartImage />
          <TitleCard>
            Психологическая помощь при разводе —
            <br /> это поддержка и путь к адаптации и восстановлению
          </TitleCard>
          <TextWrapper>
            Онлайн-консультации с психологами делают этот процесс более
            доступным и удобным, позволяя клиентам получать помощь в любое время
            и в любом месте.
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
