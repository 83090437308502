import React from 'react'
import {
  CardContainer,
  Content,
  RuporImage,
  TextWrapper,
  Wrapper
} from './ImportantUnderstanding.styles'

export const ImportantUnderstanding = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <RuporImage />
          <TextWrapper>
            Важно понимать, что психолог работает как нейтральный посредник,
            который помогает вам общаться и слушать друг друга, а также
            разрабатывать стратегии улучшения коммуникации и решения конфликтов
            и для сохранения отношений, и для комфортного их разрыва.
          </TextWrapper>
        </CardContainer>
      </Content>
    </section>
  </Wrapper>
)
