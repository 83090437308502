import React from 'react'
import {
  Card,
  CardsContainer,
  Content,
  ImageWrapper,
  TextWrapper,
  TitleCard,
  Wrapper
} from './CardsBlock.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { cardData } from './cardData'

export const CardsBlock = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <CardsContainer>
            {cardData.map((card, index) => (
              <Card key={index}>
                <ImageWrapper>{card.image}</ImageWrapper>
                <TextWrapper>
                  <TitleCard>{card.title}</TitleCard>
                  <Text.Large semiBold>{card.text}</Text.Large>
                </TextWrapper>
              </Card>
            ))}
          </CardsContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
