import React from 'react'
import {
  Content,
  DescriptionWrapper,
  Wrapper
} from './OnlineConsultationYoutalk.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const OnlineConsultationYoutalk = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>
              Онлайн-консультация психолога при разводе в YouTalk
            </Title.H2>
          </TitleWrapper>
          <DescriptionWrapper>
            <Text.Large semiBold>
              С развитием информационных технологий онлайн-консультации заняли
              прочное место в психотерапии. Это удобный и доступный способ
              получить помощь, особенно для занятых людей или тех, кто живет
              вдали от крупных городов с широким выбором хороших психологов.
              Онлайн-консультации позволяют паре обсудить свои проблемы с
              опытным психологом, не покидая дома.
            </Text.Large>
            <Text.Large semiBold>
              В YouTalk вы можете получить индивидуальные консультации по теме
              развода, парную психотерапию и консультацию для родителей и детей,
              не выходя из дома. Выберите интересные вам форматы, заполните
              анкету и умный алгоритм или консультант-диагност подберут
              подходящего психолога под ваш запрос.
            </Text.Large>
          </DescriptionWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
