import firstBlockIcon from '../../../../static/img/Razvod/helpChildren-image.svg'
import firstBlockIcon1024 from '../../../../static/img/Razvod/helpChildren-image-1024.svg'
import firstBlockIcon320 from '../../../../static/img/Razvod/helpChildren-image-320.svg'
import firstBlockIcon414 from '../../../../static/img/Razvod/helpChildren-image-414.svg'
import firstBlockIcon768 from '../../../../static/img/Razvod/helpChildren-image-768.svg'
import fourthBlockIcon from '../../../../static/img/Razvod/workWith-image.svg'
import fourthBlockIcon1024 from '../../../../static/img/Razvod/workWith-image-1024.svg'
import fourthBlockIcon320 from '../../../../static/img/Razvod/workWith-image-320.svg'
import fourthBlockIcon414 from '../../../../static/img/Razvod/workWith-image-414.svg'
import fourthBlockIcon768 from '../../../../static/img/Razvod/workWith-image-768.svg'
import secondBlockIcon from '../../../../static/img/Razvod/adaptation-image.svg'
import secondBlockIcon1024 from '../../../../static/img/Razvod/adaptation-image-1024.svg'
import secondBlockIcon320 from '../../../../static/img/Razvod/adaptation-image-320.svg'
import secondBlockIcon414 from '../../../../static/img/Razvod/adaptation-image-414.svg'
import secondBlockIcon768 from '../../../../static/img/Razvod/adaptation-image-768.svg'
import thirdBlockIcon from '../../../../static/img/Razvod/communication-image.svg'
import thirdBlockIcon1024 from '../../../../static/img/Razvod/communication-image-1024.svg'
import thirdBlockIcon320 from '../../../../static/img/Razvod/communication-image-320.svg'
import thirdBlockIcon414 from '../../../../static/img/Razvod/communication-image-414.svg'
import thirdBlockIcon768 from '../../../../static/img/Razvod/communication-image-768.svg'

import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleH3asH2style = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`
export const TitleCard = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (max-width: ${size.sm}) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${size.xs}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const Br = styled.br`
  @media (max-width: ${size.md}) {
    display: none;
  }
`

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    flex-direction: column;

    &:nth-child(even) {
      flex-direction: column-reverse;
    }
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  @media (max-width: ${size.md}) {
    gap: 24px;
  }

  @media (max-width: ${size.sm}) {
    gap: 32px;
  }
`

export const FirstBlockImage = styled.img.attrs(() => ({}))`
  content: url(${firstBlockIcon});
  width: 588px;
  height: 468px;

  @media (max-width: ${size.lg}) {
    content: url(${firstBlockIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    content: url(${firstBlockIcon768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${firstBlockIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${firstBlockIcon320});
  }
`

export const SecondBlockImage = styled.img.attrs(() => ({}))`
  content: url(${secondBlockIcon});
  width: 588px;
  height: 468px;

  @media (max-width: ${size.lg}) {
    content: url(${secondBlockIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    content: url(${secondBlockIcon768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${secondBlockIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${secondBlockIcon320});
  }
`

export const ThirdBlockImage = styled.img.attrs(() => ({}))`
  content: url(${thirdBlockIcon});
  width: 588px;
  height: 468px;

  @media (max-width: ${size.lg}) {
    content: url(${thirdBlockIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    content: url(${thirdBlockIcon768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${thirdBlockIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${thirdBlockIcon320});
  }
`

export const FourthBlockImage = styled.img.attrs(() => ({}))`
  content: url(${fourthBlockIcon});
  width: 588px;
  height: 468px;

  @media (max-width: ${size.lg}) {
    content: url(${fourthBlockIcon1024});
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    content: url(${fourthBlockIcon768});
    width: 100%;
    height: 341px;
  }

  @media (max-width: ${size.sm}) {
    content: url(${fourthBlockIcon414});
  }

  @media (max-width: ${size.xs}) {
    content: url(${fourthBlockIcon320});
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const InfoContainer = styled.div``
