import React from 'react'
import {
  Br,
  Content,
  DescriptionWrapper,
  HeroImage,
  ImageWrapper,
  InfoContainer,
  TitleSection,
  Wrapper
} from './RazvodIsImportant.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const RazvodIsImportant = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <ImageWrapper>
            <HeroImage alt="Развод – важный момент в жизни" />
          </ImageWrapper>
          <InfoContainer>
            <TitleWrapper>
              <TitleSection>
                Развод – важный момент
                <Br /> в жизни
              </TitleSection>
            </TitleWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Люди разводятся по совершенно разным причинам: одни расходятся
                потому, что брак исчерпал себя, другие бегут от разрушающих
                отношений, третьи сталкиваются с предательством партнера, а
                четвертые уходят от абьюза.
              </Text.Large>
              <Text.Large semiBold>
                Независимо от причины, это процесс, сопряженный с огромным
                количеством эмоциональных, практических и юридических аспектов.
                Вот почему развод может вызвать разные психологические
                трудности:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
